import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import VueCookies from 'vue-cookies';
import YmapPlugin from 'vue-yandex-maps';

const vueConfig = require('vue-config');

Vue.use(VueCookies);

const configs = {
  //API: 'http://localhost:11017/',
  API: 'https://api.qcontact.ru/',
  APIV2: 'https://accred.ru/qcontact/',
  APIV2Tavrida: 'https://accred.crki.art/qcontact/',
};

const settings = {
  apiKey: '2066e304-cee0-4ed2-9cb2-5f6521d5f96c',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
};

Vue.use(YmapPlugin, settings);

Vue.use(vueConfig, configs);
// set default config
Vue.$cookies.config('60d');

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
  },
});
