import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const QR = () => import("@/views/QR");
const Ticket = () => import("@/views/Ticket");
Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Главная",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Визитка",
          component: Dashboard,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "tcontact/:id",
          name: "Контакт",
          component: Dashboard,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "event/:eventid/:pid",
          name: "Контакт",
          component: Dashboard,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "qr/:id",
          name: "QR",
          component: QR,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "ticket/:eventid/:pid",
          name: "Ticket",
          component: Ticket,
          props: (route) => ({ token: route.query.token }),
        },
      ],
    },
  ];
}
