// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const telephone = ['24 24',`
<path
	d="M20.1064 15.0501C18.9334 15.0523 17.7675 14.8669 16.6531 14.5008C16.132 14.3186 15.5529 14.4413 15.1504 14.819L12.9637 16.4687C10.6153 15.2896 8.70984 13.3846 7.53036 11.0364L9.13643 8.90236C9.54192 8.49658 9.68694 7.89843 9.51238 7.352C9.14493 6.23602 8.9585 5.06846 8.96011 3.89358C8.96011 3.05414 8.2796 2.37363 7.44016 2.37363H3.89358C3.05414 2.37363 2.37363 3.05414 2.37363 3.89358C2.38479 13.6825 10.3175 21.6152 20.1064 21.6264C20.9459 21.6264 21.6264 20.9459 21.6264 20.1064V16.57C21.6264 15.7306 20.9459 15.0501 20.1064 15.0501Z"
	fill="#009688"
/>
<path
	d="M21.1198 10.4801C20.8399 10.4801 20.6131 10.2532 20.6131 9.9734C20.6086 6.33762 17.6624 3.39138 14.0266 3.38692C13.7468 3.38692 13.5199 3.16006 13.5199 2.88025C13.5199 2.60043 13.7468 2.37363 14.0266 2.37363C18.222 2.37809 21.6219 5.77799 21.6264 9.9734C21.6264 10.2532 21.3996 10.4801 21.1198 10.4801Z"
	fill="#009688"
/>
<path
	d="M17.0665 10.4801C16.7867 10.4801 16.5598 10.2532 16.5598 9.9734C16.5598 8.57433 15.4257 7.44016 14.0266 7.44016C13.7468 7.44016 13.5199 7.2133 13.5199 6.93349C13.5199 6.65368 13.7468 6.42682 14.0266 6.42682C15.9853 6.42682 17.5732 8.0147 17.5732 9.9734C17.5732 10.2532 17.3463 10.4801 17.0665 10.4801Z"
	fill="#009688"
/>`
]

export const email = ['24 24',`
	<g clip-path="url(#clip0)">
              <path
                d="M22.4684 18.5083C22.0332 19.0308 21.3884 19.3331 20.7084 19.3333H3.2917C2.61168 19.3331 1.96689 19.0308 1.53171 18.5083L9.93754 11.505L10.8084 12.1008C11.5257 12.5958 12.4744 12.5958 13.1917 12.1008L14.0626 11.505L22.4684 18.5083Z"
                fill="#1E88E5"
              />
              <path
                d="M22.5966 5.66588L14.0625 11.505L13.1917 12.1009C12.4743 12.5959 11.5257 12.5959 10.8083 12.1009L9.93747 11.505L1.40338 5.65668C1.83281 5.03746 2.53814 4.66768 3.29173 4.66669H20.7083C21.4653 4.66269 22.1741 5.03772 22.5966 5.66588Z"
                fill="#64B5F6"
              />
              <path
                d="M9.93752 11.505L1.53169 18.5084C1.18764 18.0971 0.999396 17.5779 1.00004 17.0417V6.95838C0.997248 6.49333 1.13814 6.03872 1.40339 5.65673L9.93752 11.505Z"
                fill="#2196F3"
              />
              <path
                d="M23 6.95838V17.0417C23.0006 17.5779 22.8124 18.0972 22.4683 18.5084L14.0625 11.505L22.5967 5.66589C22.8606 6.04513 23.0014 6.49634 23 6.95838Z"
                fill="#2196F3"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="22"
                  height="22"
                  fill="white"
                  transform="translate(1 1)"
                />
              </clipPath>
            </defs>
`]

export const telegram = ['24 24', `
	<path
              d="M9.62372 14.9266L9.25847 20.0639C9.78104 20.0639 10.0074 19.8394 10.2788 19.5699L12.7287 17.2285L17.8053 20.9462C18.7364 21.4651 19.3923 21.1918 19.6435 20.0897L22.9757 4.47535L22.9767 4.47443C23.272 3.09811 22.4789 2.5599 21.5718 2.89755L1.98492 10.3965C0.648152 10.9154 0.668392 11.6606 1.75768 11.9982L6.76526 13.5558L18.3969 6.27764C18.9443 5.91516 19.442 6.11572 19.0326 6.4782L9.62372 14.9266Z"
              fill="#039BE5"
            />
`]


export const facebook = ['24 24', `
<path
	d="M15.3679 5.30859H17.1971V2.12277C16.8815 2.07935 15.7962 1.98167 14.5322 1.98167C8.74498 1.98167 10.3195 8.53532 10.0891 9.49541H7.17795V13.0569H10.0883V22.0183H13.6565V13.0578H16.4491L16.8924 9.49624H13.6556C13.8126 7.1386 13.0203 5.30859 15.3679 5.30859Z"
	fill="#3B5999"
/>
`]
export const vkontakte = ['24 24', `
	<path
              d="M19.0496 12.9156C18.704 12.4791 18.8029 12.285 19.0496 11.8949C19.054 11.8904 21.9068 7.94831 22.2008 6.61142L22.2025 6.61053C22.3486 6.12334 22.2025 5.76529 21.4962 5.76529H19.1591C18.5642 5.76529 18.2898 6.07257 18.1429 6.41637C18.1429 6.41637 16.9529 9.26472 15.2696 11.1111C14.7263 11.6446 14.4751 11.8156 14.1785 11.8156C14.0324 11.8156 13.8053 11.6446 13.8053 11.1574V6.61053C13.8053 6.02625 13.6388 5.76529 13.1462 5.76529H9.47135C9.09816 5.76529 8.87638 6.03783 8.87638 6.29167C8.87638 6.84567 9.71806 6.97303 9.80535 8.5317V11.9136C9.80535 12.6546 9.67086 12.7909 9.37248 12.7909C8.57801 12.7909 6.64971 9.93094 5.50699 6.65774C5.27631 6.02269 5.05097 5.76618 4.45155 5.76618H2.11355C1.44644 5.76618 1.31195 6.07346 1.31195 6.41726C1.31195 7.02469 2.10642 10.0449 5.00643 14.0351C6.93918 16.7588 9.66106 18.2346 12.1371 18.2346C13.6254 18.2346 13.8071 17.9069 13.8071 17.3431C13.8071 14.7405 13.6726 14.4947 14.4181 14.4947C14.7637 14.4947 15.3587 14.6657 16.7481 15.9795C18.3361 17.5372 18.5971 18.2346 19.486 18.2346H21.8231C22.4893 18.2346 22.8269 17.9069 22.6327 17.2602C22.1883 15.9002 19.185 13.1026 19.0496 12.9156Z"
              fill="#4B729F"
            />
`]
export const instagram = [' 48 48', `
	 <g clip-path="url(#clip0)">
<path d="M2.99991 3.26597C-0.77209 7.18398 -8.97646e-05 11.346 -8.97646e-05 23.99C-8.97646e-05 34.49 -1.83209 45.016 7.75591 47.494C10.7499 48.264 37.2779 48.264 40.2679 47.49C44.2599 46.46 47.5079 43.222 47.9519 37.576C48.0139 36.788 48.0139 11.206 47.9499 10.402C47.4779 4.38798 43.7759 0.921972 38.8979 0.219971C37.7799 0.0579709 37.5559 0.00997082 31.8199 -2.91914e-05C11.4739 0.00997082 7.01391 -0.89603 2.99991 3.26597Z" fill="url(#paint0_linear)"/>
<path d="M23.9956 6.27797C16.7336 6.27797 9.83763 5.63197 7.20363 12.392C6.11563 15.184 6.27363 18.81 6.27363 24.002C6.27363 28.558 6.12763 32.84 7.20363 35.61C9.83163 42.374 16.7836 41.726 23.9916 41.726C30.9456 41.726 38.1156 42.45 40.7816 35.61C41.8716 32.79 41.7116 29.218 41.7116 24.002C41.7116 17.078 42.0936 12.608 38.7356 9.25197C35.3356 5.85197 30.7376 6.27797 23.9876 6.27797H23.9956ZM22.4076 9.47197C37.5556 9.44797 39.4836 7.76397 38.4196 31.158C38.0416 39.432 31.7416 38.524 23.9976 38.524C9.87763 38.524 9.47163 38.12 9.47163 23.994C9.47163 9.70397 10.5916 9.47997 22.4076 9.46797V9.47197ZM33.4556 12.414C32.2816 12.414 31.3296 13.366 31.3296 14.54C31.3296 15.714 32.2816 16.666 33.4556 16.666C34.6296 16.666 35.5816 15.714 35.5816 14.54C35.5816 13.366 34.6296 12.414 33.4556 12.414ZM23.9956 14.9C18.9696 14.9 14.8956 18.976 14.8956 24.002C14.8956 29.028 18.9696 33.102 23.9956 33.102C29.0216 33.102 33.0936 29.028 33.0936 24.002C33.0936 18.976 29.0216 14.9 23.9956 14.9ZM23.9956 18.094C31.8056 18.094 31.8156 29.91 23.9956 29.91C16.1876 29.91 16.1756 18.094 23.9956 18.094Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear" x1="3.09195" y1="44.9342" x2="47.7029" y2="6.32398" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFDD55"/>
<stop offset="0.5" stop-color="#FF543E"/>
<stop offset="1" stop-color="#C837AB"/>
</linearGradient>
<clipPath id="clip0">
<rect width="48" height="48" fill="white"/>
</clipPath>
</defs> `]
export const tiktok = ['24 24', `
	<path
              d="M17.9956 0.277968C10.7336 0.277968 3.83763 -0.368032 1.20363 6.39197C0.115629 9.18397 0.273629 12.81 0.273629 18.002C0.273629 22.558 0.127629 26.84 1.20363 29.61C3.83163 36.374 10.7836 35.726 17.9916 35.726C24.9456 35.726 32.1156 36.45 34.7816 29.61C35.8716 26.79 35.7116 23.218 35.7116 18.002C35.7116 11.078 36.0936 6.60797 32.7356 3.25197C29.3356 -0.148032 24.7376 0.277968 17.9876 0.277968H17.9956ZM16.4076 3.47197C31.5556 3.44797 33.4836 1.76397 32.4196 25.158C32.0416 33.432 25.7416 32.524 17.9976 32.524C3.87763 32.524 3.47163 32.12 3.47163 17.994C3.47163 3.70397 4.59163 3.47997 16.4076 3.46797V3.47197ZM27.4556 6.41397C26.2816 6.41397 25.3296 7.36597 25.3296 8.53997C25.3296 9.71397 26.2816 10.666 27.4556 10.666C28.6296 10.666 29.5816 9.71397 29.5816 8.53997C29.5816 7.36597 28.6296 6.41397 27.4556 6.41397ZM17.9956 8.89997C12.9696 8.89997 8.89563 12.976 8.89563 18.002C8.89563 23.028 12.9696 27.102 17.9956 27.102C23.0216 27.102 27.0936 23.028 27.0936 18.002C27.0936 12.976 23.0216 8.89997 17.9956 8.89997ZM17.9956 12.094C25.8056 12.094 25.8156 23.91 17.9956 23.91C10.1876 23.91 10.1756 12.094 17.9956 12.094Z"
              fill="white"
            />
`]
export const twitter = ['40 40', `
	<path
              d="M43.104 11.498C43.104 11.064 43.088 10.642 43.068 10.226C45.02 8.84 46.662 7.11 48 5.118V5.116C46.214 5.898 44.314 6.418 42.33 6.67C44.37 5.452 45.928 3.538 46.66 1.232C44.758 2.366 42.658 3.166 40.42 3.614C38.614 1.69 36.04 0.5 33.232 0.5C27.784 0.5 23.398 4.922 23.398 10.342C23.398 11.122 23.464 11.872 23.626 12.586C15.446 12.186 8.206 8.266 3.342 2.292C2.494 3.766 1.994 5.452 1.994 7.266C1.994 10.674 3.748 13.694 6.366 15.444C4.784 15.414 3.234 14.954 1.92 14.232V14.34C1.92 19.122 5.33 23.094 9.804 24.01C8.3 24.422 6.448 24.406 5.362 24.166C6.636 28.062 10.256 30.928 14.556 31.022C11.208 33.64 6.956 35.218 2.354 35.218C1.548 35.218 0.774 35.182 0 35.084C4.36 37.894 9.524 39.5 15.096 39.5C33.204 39.5 43.104 24.5 43.104 11.498Z"
              fill="#55ACEE"
            />
`]
// export const telephone = ['24 24', `

// `]